// Desktop Styles
.hk-desktop-document-card {
  --background: var(--hk-color-oxygen);
  --border-radius: 10px;
  margin-bottom: 4px;
  --min-height: 100px;
  height: 100px;
  cursor: pointer;
  &:hover {
    border: 2px solid var(--hk-color-spock);
    border-radius: 10px;
    transition: 50ms ease;
  }
  &-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    background: #fff;
    margin: 26px 24px 26px 8px;
    img {
      border-radius: 4px;
      object-fit: cover;
    }
  }
  &-label {
    display: flex !important;
    align-items: center;
  }
  &-title {
    flex: 1;
    text-align: left;
    margin-bottom: 4px !important;
    h2 {
      font-size: 15px !important;
      font-weight: bold !important;
    }
  }

  &-description {
    flex: 1;
    text-align: left;
    padding-top: 4px !important;
    p {
      color: var(--hk-color-klingon);
      font-size: 14px;
    }
  }
  &-marker {
    position: absolute;
    right: 0;
    background-color: var(--hk-color-spock);
    height: 100%;
    width: 10px;
    border-radius: 0px 10px 10px 0px;
  }
  &-arrow {
    color: black;
    margin-right: 18px;
  }
}

// Mobile Styles
.hk-document-card {
  --background: var(--hk-color-oxygen);
  --border-radius: 8px;
  margin-bottom: 8px;
  &-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    background: #fff;
    img {
      border-radius: 4px;
      object-fit: cover;
    }
  }
  &-label {
    margin: 16px 0;
  }
  &-title {
    text-align: left;
    margin-bottom: 4px !important;
    h2 {
      font-size: 15px !important;
      font-weight: bold !important;
    }
  }

  &-description {
    text-align: left;
    padding-top: 4px !important;
    p {
      font-size: 14px;
      color: var(--hk-color-voyager);
    }
  }
  &-marker {
    position: absolute;
    right: 0;
    background-color: var(--hk-color-spock);
    height: 100%;
    width: 10px;
    border-radius: 0px 8px 8px 0px;
  }
}
